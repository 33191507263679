<template>
  <div class="form-wrapper forgetPwd-form">
    <div class="content-form" v-show="step === 1">
      <h1>忘记密码</h1>
      <div class="back-btn" @click="$emit('backLogin')">&lt; 返回登录</div>
      <div class="form-box">
        <a-form :form="form">
          <a-form-item>
            <a-input
              v-model="form.phone"
              placeholder="请输入手机号"
              size="large"
              @focus="readOnly = false"
              :readOnly="readOnly"
            >
              <a-icon type="mobile" slot="prefix"></a-icon>
            </a-input>
          </a-form-item>
          <a-form-item>
            <div class="flex">
              <a-input v-model="form.code" placeholder="请输入验证码" size="large">
                <a-icon type="check-circle" slot="prefix"></a-icon>
              </a-input>
              <CodeWithCD class="count-down-btn" :phone="form.phone" @error="showError" @send="sendCode"></CodeWithCD>
            </div>
          </a-form-item>
          <a-form-item class="err-msg-form">
            <div class="err-msg">{{ errMsg }}</div>
          </a-form-item>
          <a-button @click="handleNext" :loading="loading" class="form-btn">下一步</a-button>
        </a-form>
      </div>
    </div>
    <div class="content-form" v-show="step === 2">
      <h1>重置密码</h1>
      <div class="back-btn" @click="changeStep(1)">&lt; 上一步</div>
      <div class="form-box">
        <a-form :form="form">
          <a-form-item>
            <a-input-password v-model="form.password" autocomplete="off" placeholder="请输入新密码" size="large">
              <a-icon type="lock" slot="prefix"></a-icon>
            </a-input-password>
          </a-form-item>
          <a-form-item>
            <a-input-password
              v-model="form.confirmPassword"
              autocomplete="off"
              placeholder="请再次输入新密码"
              size="large"
            >
              <a-icon type="lock" slot="prefix"></a-icon>
            </a-input-password>
          </a-form-item>
          <a-form-item class="err-msg-form">
            <div class="err-msg">{{ errMsg }}</div>
          </a-form-item>
          <a-button @click="handleSubmit" :loading="loading" class="form-btn">提交</a-button>
        </a-form>
      </div>
    </div>
  </div>
</template>

<script>
import CodeWithCD from '@/components/CodeWithCD'
import Reg from '@/utils/reg'
export default {
  components: {
    CodeWithCD,
  },
  data() {
    return {
      step: 1,
      readOnly: true,
      form: {
        phone: '',
        code: '',
        password: '',
        confirmPassword: '',
      },

      errMsg: '',
      loading: false,
    }
  },
  methods: {
    showError(err) {
      this.$message.error(err.message)
    },
    async sendCode(phone) {
      await this.$axios.post('/ums/weblogin/messageCode', {
        phone,
      })
      this.$message.success('发送验证码成功')
    },
    resetError() {
      this.errMsg = ''
    },
    checkInput(step) {
      const { phone, code, password, confirmPassword } = this.form
      if (step === 1) {
        if (!phone) {
          this.errMsg = '请输入手机号'
          return false
        }

        if (!code) {
          this.errMsg = '请输入验证码'
          return false
        }
      }
      if (step === 2) {
        if (!password) {
          this.errMsg = '请输入新密码'
          return false
        }

        if (!password.match(Reg.isPwd)) {
          this.errMsg = '密码6-20位，必须包含数字和字母'
          return false
        }
        if (!confirmPassword) {
          this.errMsg = '请再次输入新密码'
          return false
        }
        if (confirmPassword !== password) {
          this.errMsg = '两次输入新密码不一致，请确认'
          return false
        }
      }
      return true
    },
    changeStep(step) {
      this.step = step
      this.errMsg = ''
    },
    async handleNext() {
      if (this.checkInput(1)) {
        this.changeStep(2)
      }
    },
    async handleSubmit(e) {
      if (this.checkInput(2)) {
        try {
          await this.$axios.post('/ums/weblogin/updatePwdCode', this.form)
          this.$message.success('重置密码成功，请重新登录')
          this.$emit('backLogin')
        } catch (e) {
          this.errMsg = e.message
        }
      }
    },
  },
  mounted() {},
}
</script>

<style scoped lang="less">
.count-down-btn {
  width: 150px;
  background: rgba(255, 255, 255, 0.5);
  color: #000;
  font-size: 14px;
  border-radius: 4px;
  margin-left: 10px;
  text-align: center;
  cursor: pointer;
  &:not(.disabled):hover {
    color: #333;
    opacity: 0.8;
  }
  &.disabled {
    opacity: 0.4;
    color: #fff;
    cursor: not-allowed;
  }
}
</style>
