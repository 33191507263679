<template>
  <section class="login-page" id="particles-js">
    <div class="login-logo"></div>
    <div class="login-title"></div>
    <div class="feature-list">
      <div class="feature-list-item"></div>
      <div class="feature-list-item"></div>
      <div class="feature-list-item"></div>
      <div class="feature-list-item"></div>
    </div>
    <LoginForm ref="LoginForm" @forgetPwd="view = 'forgetPwd'" v-if="view === 'login'"></LoginForm>
    <ForgetPwdForm @backLogin="backLogin" v-if="view === 'forgetPwd'"></ForgetPwdForm>
  </section>
</template>
<script>
import loadjs from 'loadjs'
import LoginForm from './modules/LoginForm'
import ForgetPwdForm from './modules/ForgetPwdForm'
export default {
  components: {
    LoginForm,
    ForgetPwdForm,
  },
  data() {
    return {
      view: 'login',
    }
  },
  methods: {
    async backLogin() {
      this.view = 'login'
      await this.$nextTick()
      // 通过readOnly 组织默认填充
      this.$refs.LoginForm.readOnly = true
    },
  },
  async mounted() {
    await loadjs('/libs/particles/particles.js', { returnPromise: true })
    window.particlesJS('particles-js', {
      particles: {
        number: {
          value: 24,
          density: {
            enable: true,
            value_area: 800,
          },
        },
        color: {
          value: '#ffffff',
        },
        shape: {
          type: 'circle',
          stroke: {
            width: 0,
            color: '#000000',
          },
          polygon: {
            nb_sides: 5,
          },
          image: {
            src: 'img/github.svg',
            width: 100,
            height: 100,
          },
        },
        opacity: {
          value: 0.5,
          random: false,
          anim: {
            enable: false,
            speed: 1,
            opacity_min: 0.1,
            sync: false,
          },
        },
        size: {
          value: 3,
          random: true,
          anim: {
            enable: false,
            speed: 40,
            size_min: 0.1,
            sync: false,
          },
        },
        line_linked: {
          enable: true,
          distance: 150,
          color: '#ffffff',
          opacity: 0.4,
          width: 1,
        },
        move: {
          enable: true,
          speed: 6,
          direction: 'none',
          random: false,
          straight: false,
          out_mode: 'out',
          bounce: false,
          attract: {
            enable: false,
            rotateX: 600,
            rotateY: 1200,
          },
        },
      },
      interactivity: {
        detect_on: 'canvas',
        events: {
          onhover: {
            enable: true,
            mode: 'repulse',
          },
          onclick: {
            enable: true,
            mode: 'push',
          },
          resize: true,
        },
        modes: {
          grab: {
            distance: 400,
            line_linked: {
              opacity: 1,
            },
          },
          bubble: {
            distance: 400,
            size: 40,
            duration: 2,
            opacity: 8,
            speed: 3,
          },
          repulse: {
            distance: 200,
            duration: 0.4,
          },
          push: {
            particles_nb: 4,
          },
          remove: {
            particles_nb: 2,
          },
        },
      },
      retina_detect: true,
    })
  },
}
</script>
<style lang="less" scoped>
.login-page {
  width: 100%;
  height: 100%;
  position: relative;
  min-height: 820px;
  overflow: hidden;
  background: url('~@/assets/images/login/page_bg.png') bottom center / 100% auto no-repeat,
    url('~@/assets/images/login/bg_extend.png') top center / 100% auto;
}
.login-title {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  margin: auto;
  width: 1300px;
  font-size: 50px;
  font-weight: 500;
  color: #fff;
  text-align: center;
  height: 100px;
  background: url('~@/assets/images/login/title.png') top center / 100% auto no-repeat;
}
.feature-list {
  position: absolute;
  top: 120px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  .feature-list-item {
    width: 177px;
    height: 68px;
    margin: 0 20px;
  }
  .feature-list-item:nth-child(1) {
    background: url('~@/assets/images/login/list_1.png');
  }
  .feature-list-item:nth-child(2) {
    background: url('~@/assets/images/login/list_2.png');
  }
  .feature-list-item:nth-child(3) {
    background: url('~@/assets/images/login/list_3.png');
  }
  .feature-list-item:nth-child(4) {
    background: url('~@/assets/images/login/list_4.png');
  }
}
.login-logo {
  width: 25%;
  height: 75px;
  position: absolute;
  top: 0px;
  left: 50px;
  background: url('~@/assets/images/login/logo.png') center center / 100% auto no-repeat;
}
::v-deep {
  .form-wrapper {
    width: 634px;
    height: 505px;
    top: 200px;
    background: url('~@/assets/images/login/login_box.png') center center / 100% auto;
    position: absolute;
    right: 0;
    left: 0;
    margin: 0 auto;
    .content-form {
      width: 396px;
      height: 100%;
      position: absolute;
      left: 120px;
      h1 {
        font-size: 20px;
        font-weight: 600;
        color: #fff;
        text-align: center;
        margin: 95px 0 45px;
      }
      .form-box {
        input:-webkit-autofill {
          // -webkit-text-fill-color: #333;
          box-shadow: 0 0 0px 1000px rgb(170, 165, 164) inset !important;
        }
        .ant-input-prefix {
          font-size: 18px;
          color: #fff;
        }
        .ant-input-password {
          .ant-input-suffix {
            right: 20px;
            .anticon {
              font-size: 20px;
              color: #fff;
            }
          }
        }
        .code-input {
          .ant-input {
            padding-left: 20px;
          }
          .ant-input-suffix {
            height: 45px;
            width: 110px;
            display: flex;
            padding: 4px;
            align-items: center;

            margin-right: -10px;
            .code-img {
              width: 100%;
              height: 100%;
              background: skyblue;
              border-radius: 30px;
            }
          }
        }
        .ant-input {
          height: 40px;
          padding-left: 40px;
          color: #fff;
          background: rgba(255, 255, 255, 0.3) !important;
          border: 0 !important;
          outline: 0 !important;
          &:focus,
          &:hover,
          &:active {
            border: 0;
            box-shadow: none;
          }
        }
        .ant-checkbox-inner {
          background: rgba(255, 255, 255, 0.3);
          border: 0;
        }

        .ant-form-item {
          margin-bottom: 20px;
        }
        .err-msg-form {
          position: relative;
          top: -10px;
          margin-bottom: 0;
          .err-msg {
            color: red;
            white-space: nowrap;
            text-overflow: ellipsis;
            width: 100%;
            overflow: hidden;
          }
        }
        .form-btn {
          height: 40px;
          width: 100%;
          position: absolute;
          bottom: 110px;
          width: 400px;

          border-radius: 4px;
          font-size: 16px;
          font-weight: 400;
          color: #ffffff;
          line-height: 40px;
          background: #0fb4fb;
          border: 0 !important;
        }
      }
    }
  }
  .login-form {
    .deco {
      background: url('~@/assets/images/login/deco.png') center center / 100% auto no-repeat;
    }
    .login-extra {
      position: relative;
      margin-top: -15px;
      margin-bottom: 15px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      color: #fff;
    }
  }
  .forgetPwd-form {
    .back-btn {
      position: absolute;
      top: 70px;
      left: -50px;
      cursor: pointer;
      color: #fff;
    }
  }
}
</style>
