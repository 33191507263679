<template>
  <div class="component-codeWithCD" @click="clickHandle" :class="{ disabled: wait != 0 }">
    {{ wait == 0 ? '发送验证码' : `${wait}s` }}
  </div>
</template>
<script>
import Reg from '@/utils/reg'
export default {
  data() {
    return {
      wait: 0,
    }
  },
  components: {},
  props: {
    phone: {
      required: true,
      type: String,
    },
    showErrorWhenWait: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    clickHandle() {
      const isMobile = Reg.isMobile
      let timer = null
      if (this.phone !== '*') {
        if (this.phone === '') {
          this.$emit('error', new Error('手机号不能为空'))
          return
        }
        if (!this.phone.match(isMobile)) {
          this.$emit('error', new Error('您输入的手机号无效，请更正后重试'))

          return
        }
      }
      if (this.wait !== 0) {
        if (this.showErrorWhenWait) {
          this.$emit('error', new Error('等待中，请在倒计时完成后再试'))
        }

        return
      }
      var countDown = () => {
        if (this.wait === 0) {
          clearInterval(timer)
          return
        }
        this.wait--
      }
      this.wait = 60
      clearInterval(timer)
      timer = setInterval(countDown, 1000)
      this.$emit('send', this.phone)
    },
  },
}
</script>
<style scoped lang="less"></style>
