<template>
  <div class="form-wrapper login-form">
    <div class="content-form">
      <h1 class="deco">用户登录</h1>
      <div class="form-box">
        <a-form :form="form">
          <a-form-item>
            <a-input
              v-model="form.username"
              placeholder="请输入账号"
              size="large"
              @focus="readOnly = false"
              :readOnly="readOnly"
            >
              <a-icon type="user" slot="prefix"></a-icon>
            </a-input>
          </a-form-item>
          <a-form-item>
            <a-input-password
              v-model="form.password"
              placeholder="请输入密码"
              @keypress.enter="handleSubmit"
              size="large"
            >
              <a-icon type="lock" slot="prefix"></a-icon>
            </a-input-password>
          </a-form-item>

          <div class="login-extra">
            <div>
              <a-checkbox v-model="form.rememberMe" class="mr-1"></a-checkbox>
              记住我
            </div>
            <div class="cursor-pointer" @click="$emit('forgetPwd')">忘记密码?</div>
          </div>
          <a-form-item class="err-msg-form">
            <div class="err-msg">{{ errMsg }}</div>
          </a-form-item>
          <a-button @click="handleSubmit" :loading="loading" class="form-btn">登录</a-button>
        </a-form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        username: '',
        password: '',
        rememberMe: true,
      },
      readOnly: false,
      errMsg: '',
      loading: false,
    }
  },
  methods: {
    resetError() {
      this.errMsg = ''
    },
    checkInput() {
      const { username, password } = this.form

      if (!username) {
        this.errMsg = '请输入账号'
        return false
      }

      if (!password) {
        this.errMsg = '请输入密码'
        return false
      }
      return true
    },
    async handleSubmit(e) {
      if (!this.checkInput()) {
        return false
      }
      this.loading = true
      try {
        const res = await this.$store.dispatch('Login', this.form)
        await this.loginSuccess(res)
      } catch (e) {
        this.requestFailed(e)
      }
      this.loading = false
    },
    async loginSuccess(res) {
      const toPath = decodeURIComponent(this.$route.query.redirect || '/')

      await this.$router.replace(toPath).catch((e) => {
        // console.log(e)
      })
      try {
        const { data } = await this.$axios.post('/ums/weblogin/remindUpdatePwd')
        if (data === false) {
          setTimeout(() => {
            this.$notification.success({
              message: '提示',
              description: `您的密码长时间未修改，为了您的账户安全，请及时修改密码。`,
            })
          })
        }
      } catch (e) {}
    },
    requestFailed(err) {
      this.errMsg = err.message
    },
  },
}
</script>

<style scoped lang="less"></style>
